import React, { Component } from 'react'
import { Input, Select, Button, Row, Col, Radio, Collapse, Divider } from 'antd';
import axios from 'axios';
import { tree, Infinity } from 'd3';
import Viz from './Viz.js'
import WordTreeViz from './WordTreeViz'
import Layout from '../../components/Layouts';
import { type } from 'os';

export const frontmatter = {
    title: `WordTree: Neural Text Generation`,
    written: `2019-01-07`,
    updated: `2019-01-11`,
    layoutType: `post`,
    contentType: `dataviz`,
    path: `/wordtree/`,
    category: `VISUALISATION, NEURAL TEXT GENERATION`,
    image: `./poster.png`,
    description: `Visualise neural text generation using D3`
  }

// UI Constants
const Option = Select.Option;
const Panel = Collapse.Panel;

const API = axios.create({
    baseURL: 'https://api.sprinkleai.com'
    // baseURL: 'http://192.168.1.106:9010'
});

const MODEL_ID_MAP = {
  joke: "jokerv2",
  asimov: "asimov",
  speaker: "speakerv40"
}
const DEFAULTSEEDARRAY = [
  {
    model: 'joke',
    text: 'How many days'
  },
  {
    model: 'asimov',
    text: 'Once upon a time in a galaxy'
  },
  {
    model: 'speaker',
    text: 'Hello everyone. What is the one thing'
  },
]
export default class WordTreePage extends Component {
    state = {
        loading: false,
        color: "", 
        model: "asimov", 
        seedText: "And It said, let there be",
        maxDepth: "3"
      }

    componentDidMount() {
        this.loadData();
    }
  
    loadData() {
        axios.get("https://gist.githubusercontent.com/katnoria/b6fe53f4cc993d840bad2a9c7e1ece1a/raw/9f7be05d0476a63fde3f47249e574941504818cb/wt.json")
            .then(response => {
                let dataset = response.data;
                this.setState({dataset});
            });
    }

    handleGenerateText = (evt) => {
      this.setState({loading: true});
      try {
        let {model, seedText, maxDepth} = this.state;
        const modelId = MODEL_ID_MAP[model];

        // API.post(`/${model}`, {
        API.post(`/namegen/${model}`, {
          'model': modelId,
          'seed_letter': seedText,
          'num_words': 1,
          'wordtree': 'True',
          'max_depth': maxDepth
        },{crossdomain: true}
        ).then(response => {
          this.setState({loading: false});
          // console.log(JSON.stringify(response.data));
          let dataset = response.data.name;
          this.setState({dataset});
        }).catch(error => {
          console.log(error);
          this.setState({loading: false});
        });

      } catch(error) {
        console.log(`error ${error}`);
      }
      // this.loadData();
    } 
  
    onChange = (evt) => {
      let seedText = evt.target.value;
      this.setState({seedText})
    }

    handleModelChange = (model) => {
      this.setState({model});
    }

    handleDefaultSeedChange = (defaultSeed) => {
      // console.log(`handleDefaultSeedChange: ${defaultSeed}`)
      if (defaultSeed >= 0) {
        let data = DEFAULTSEEDARRAY[parseInt(defaultSeed)]
        let model = data.model;
        let seedText = data.text;
        this.setState({defaultSeed, seedText, model});
      }
    }

    handleDepthChange = (e) => {
      const maxDepth = e.target.value;
      if (maxDepth) {
        this.setState({maxDepth});  
      }
    }

    minChars = (model) => {
      switch(model) {
        case 'joke':
          return 10
        case 'speaker':
          return 40
        case 'asimov':
          return 20
        default:
          return 20
      }
    }

    disableGenerate = (txt, model) => {    
      if (txt && model) {
        switch (model) {
          case 'joke':
            return txt.length >= 10 ? false : true
          case 'speaker':
          case 'asimov':
            return txt.length >= 20 ? false : true
          default:
            return true
        }
      } else {
        return true
      }
    }

  
    render() {
      let { loading, defaultSeed, seedText, maxDepth, model } = this.state;
      let disableGenerateButton = this.disableGenerate(seedText, model);
      const diff = this.minChars(model) - seedText.length;
      let buttonLabel = diff <= 0 ? '' : `Add ${diff} more letter(s)`
      return(
        <div className="dataviz-page">
        <Layout data={this.props.data} location={this.props.location}>
        <h1 className="header-title">Word Tree: Neural Text Generation</h1>
        <p style={{textAlign: "center", color: "gray"}}>Published: 11 Jan, 2019</p>
        <div style={{marginTop: "20px"}}>        
        <Collapse bordered={false} defaultActiveKey={['1']}>
        <Panel key="1">
        <p><span className="info-text-start">Visualise</span> neural text generation process, covered in the post <a href="/generativetext">here</a>, 
        by generating a word tree over three pre-determined 
        temperatures <span className="temp_0_3">0.3</span>, <span className="temp_0_6">0.6</span> and <span className="temp_0_9">0.9</span>.
        The temperatures [0.0, 1.0] allow us to tune the model where a number closer 
        to 0.0 will generate the text similar to the training dataset and a number closer to 1.0  
        makes it so creative that sometimes the text will not make much sense.
        </p>
        <p>
          You can either choose a predefined text from the dropdown or type your own in the textfield, select the model 
          of your choice and Generate. Give it a go.
        </p>
        </Panel>
        </Collapse>
        <div className="box">
        <Row gutter={24} style={{marginBottom: 2}}>
          <Col span={8}>
            <Select onChange={this.handleDefaultSeedChange} 
              className="default-seed-select"
              allowClear
              placeholder="Select the text from dropdown or type your own below"
              defaultValue={defaultSeed}
              style={{width: 355, color: '#a6a6a6'}}>
              {DEFAULTSEEDARRAY.map((item, i) => <Option value={i}>{item.text}</Option>)}
            </Select>          
          </Col>
          <Col span={3}>
            <span className="action-label">Model</span>
          </Col>
        <Col span={3}>
          <span className="action-label">Tree Depth</span>        
          </Col>
        </Row>
        <Row gutter={24}>
        <Col span={8}>
          <Input placeholder="Start a sentence" allowClear onChange={this.onChange} value={seedText}/>
        </Col>
        <Col span={3}>
          <Select onChange={this.handleModelChange} defaultValue={model} style={{width: 120}}>
            <Option value="joke">Joker</Option>
            <Option value="speaker">Ted Talks</Option>
            <Option value="asimov">Asimov</Option>
          </Select>          
          </Col>
        <Col span={3}>
        <Radio.Group defaultValue={maxDepth} buttonStyle="solid" onChange={this.handleDepthChange}>
          <Radio.Button value="3">3</Radio.Button>
          <Radio.Button value="4">4</Radio.Button>
        </Radio.Group>        
          </Col>
        <Col span={2}>
          <Button onClick={this.handleGenerateText} disabled={disableGenerateButton} loading={loading}>Generate</Button>
        </Col>
        </Row>
        <Row>
          <Col span={12}>
            <span className="min-chars">{buttonLabel}</span>
          </Col>
        </Row>
        </div>
          {/* { this.state.toDraw.length ? <Viz shapes={this.state.toDraw}/> : null} */}
          { this.state.dataset ? <WordTreeViz dataset={this.state.dataset} maxDepth={maxDepth}/> : null}
        </div>
        </Layout>
        </div>
      )
    }
  }