import React, { Component } from 'react';
import * as d3 from 'd3';
import './WordTree.css';

export default class WordTreeViz extends Component {

    resize = () => this.drawTree();

    componentDidMount() {
        window.addEventListener('resize', this.resize)
        this.drawTree();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    componentDidUpdate(prevProps) {
        // console.log(`WordTreeViz.componentDidUpdate ${this.props.dataset}`)
        // draw(this.props);
        this.drawTree()
    }

    drawTree() {
        draw(this.props);
    }

    render() {
        return (
            <div className="viz">                
            </div>
        )
    }
}

const tree = (data, width, height) => {
    const root = d3.hierarchy(data);
    // root.dx = 20;
    root.dx = Math.min(height * 0.025, 25);
    // console.log(`height: ${height}, ${height * 0.025}, root.dx: ${root.dx}`);
    root.dy = width / (root.height + 1);
    return d3.tree().nodeSize([root.dx, root.dy])(root);
  }

const draw = (props) => {
    d3.select('.viz > *').remove();    
    const data = props.dataset;
    const width = document ? 
        Math.min(Math.max(document.documentElement.clientWidth, window.innerWidth || 0), 1200)
        : 1200;
        
    let height = document ? 
        Math.max(document.documentElement.clientHeight - 100, window.innerHeight- 100, 500)
        : 500;

    // let height = 500;
    const aspect = width / height;
    const zoom = (props.maxDepth && parseInt(props.maxDepth) > 3) ? 1.5 : 1.1;
    const svgHeight = height*zoom;
    // console.log(props);
    // console.log(`width: ${width}, height: ${height}, aspect: ${aspect}, zoom: ${zoom}, svgHeight: ${svgHeight}`);
    const svg = d3.select('.viz').append('svg')
      .attr('viewBox', `0 0 ${width} ${svgHeight}`)
      .attr('preserveAspectRatio', 'xMinYMid')
    //   .attr('width', width)
      //   .attr('height', height, Math.round(width / aspect))
      // Commented above, added auto height which allows us to see the full tree
      .attr('width', "100%")
      .attr('height', "auto")
      .attr('id', 'svg-viz')
    //   .call(responsivefy);
    
    // To animate the path, we use clippath
    // ref: https://blog.pixelingene.com/2011/08/progressive-reveal-animations-in-svg-using-a-svgclippath/
    // svg.append('svg:clipPath')
    //     .attr('id', 'clipper')    
    //     .append('svg:rect')
    //     .attr('id', 'clip-rect');
    // Not looking for animation ATM

    // let root = d3.hierarchy(data);
    // root.dx = 30;
    // root.dy = width / (root.height + 1);
    // root = d3.tree().nodeSize([root.dx, root.dy])(root);
    
    const root = tree(data, width, height);
    // const tree = d3.tree().nodeSize([15, 150])
    // const root = d3.hierarchy(data);
    const titleText = 'Hover over the node below to see full generated text'

      let x0 = height/2;
      console.log(`x0: ${x0}`);
    //   let x0 = d3.Infinity;
      let x1 = -x0;
      root.each(d => {
        if (d.x > x1) x1 = d.x;
        if (d.x < x0) x0 = d.x;
      });

      svg.append("text")
        .attr("x", 1)
        .attr("y", 20)
        .attr("class", "gentext")
        .text(titleText);        
      
      const g = svg.append("g")      
          .attr("font-family", "sans-serif")
          .attr("font-size", 10)
          .attr("transform", `translate(${root.dy / 3},${root.dx - x0})`);
        
      const t = d3.transition()
          .duration(700)
          .ease(d3.easeLinear);

      const link = g.append("g")
        .attr("fill", "none")
        .attr("stroke", "#555")
        .attr("stroke-opacity", 0.4)
        .attr("stroke-width", 1.5)
      .selectAll(".link")
        .data(root.links())
        .enter().append("path")
          .each(function(d){ d.target.linkNode = this})
          .attr('class', 'link')
        //   .classed('label-active', false)
          .attr("d", d3.linkHorizontal()
              .x(d => d.y)
              .y(d => d.x));
      
      const node = g.append("g")
          .attr("stroke-linejoin", "round")
          .attr("stroke-width", 3)
        .selectAll(".node")        
        .data(root.descendants().reverse())
        .enter().append("g")
          .attr('class', 'node')
          .attr("transform", d => `translate(${d.y},${d.x})`)
        .on('mouseover', mouseovered(true))
        .on('mouseout', mouseovered(false));        

      node.append("circle")
        //   .attr("fill", d => d.children ? "#555" : "#999")
          .attr("fill", d => tempToColor(d.data.temp))
          .attr("r", 5);
    
      node.append("text")
          .attr("dy", d => d.data.temp == -1 ? "1.5em" : "0.20em")
          .attr("x", d => d.data.temp == -1 ? d.data.text.length : d.children ? -6 : 8)
          .attr("text-anchor", d => d.children ? "end" : "start")
          .text(d => d.data.text)
        // .clone(true).lower()
        //   .attr("stroke", "white");
      
        // node.append("text")
        //   .attr("font-size", 6)
        //   .attr("fill","grey")
        //   .attr("dy", "1.5em")
        //   .attr("x", d => d.children ? -6 : 6)
        //   .attr("text-anchor", d => d.children ? "end" : "start")
        //   .text(d => d.data.temp > 0 ? `(${d.data.temp})` : ``)
        //   .text(d => `(${d.data.temp})`)
        //   .text(d => d.data.children.length > 0 ? `(${d.data.temp})` : ``)  
        // .clone(true).lower()
        //   .attr("stroke", "white");      

        function tempToColor(temp) {
            switch(temp) {
                case 0.3:
                  return "rgb(255, 127, 14)";
                case 0.6:
                  return "rgb(44, 160, 44)"
                case 0.9:
                  return "rgb(31, 119, 180)"
                default:
                  return "#555"
            }
        }

        function mouseovered(active) {
            return function(d) {
                // let txt = active ? 'holaaaa' : '';
                let txtNodes = []
                // d3.select('.gentext').text(txt);
                d3.select(this).classed('label--active', active);
                do {
                    d3.select(d.linkNode)
                        .classed('link--active', active)
                        .raise();

                    if (d.data) {
                        txtNodes.unshift(d.data.text)
                    }
                }
                while (d = d.parent);
                let txt = active ? `Generated: ${txtNodes.join(' ')}` : titleText;
                d3.select('.gentext').classed('header--active', active).text(txt);
            }
        }
}